import React, { useContext } from 'react';
import { ThemeContext } from '../themes/ThemeContext';

const Footer: React.FC = () => {
  const { currentTheme } = useContext(ThemeContext);

  return (
    <footer className={`footer`}>
      <p className='footer__text'>Footer Component</p>
      <div className='footer__links'>
        <a href="#" className='footer__link'>Privacy Policy</a> | <a href="#" className='footer__link'>Terms of Service</a>
      </div>
    </footer>
  );
};

export default Footer;

