import React from 'react';
import Hero from '../components/Hero'; // Adjust the path based on your file structure
// Home.jsx

// Home.jsx

const Home: React.FC = () => {
  return (
    <main>
      <Hero />
      <div  className='home-content'>

      <h1 className='home-title'>Welcome to Dija Studios!</h1>
      <div className='home-text'>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
      </div>
      </div>     
    </main>
  );
};

export default Home;
