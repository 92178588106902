//HTML native selector menu. Difficult to include image or icons to the text. So if you chose this you stick to the
import React from 'react';
import { FaPalette } from 'react-icons/fa';
import { useThemeSelector, ThemeItem, SubThemeData } from './useThemeSelector';
import { DisplayMode } from '../ThemeContext'; // Import DisplayMode enum

const ThemeSwitcher: React.FC = () => {
  const { selectedTheme, handleThemeChange, themeList, displayMode } = useThemeSelector();

  const displayModes = {
    [DisplayMode.Text]: (themeItem: ThemeItem) => themeItem.name,
    [DisplayMode.Icon]: (themeItem: ThemeItem) => (
      <span
      className="icon-color"
        style={{ backgroundImage: `url(${themeItem.iconImage})` }}
      />
    ),
    [DisplayMode.Both]: (themeItem: ThemeItem) => (
      <>
        {themeItem.name}
        <span
          className="theme-icon"
          style={{ backgroundImage: `url(${themeItem.iconImage})` }}
        />
      </>
    ),
  };

  const hasSubThemeData = (theme: ThemeItem | SubThemeData): theme is SubThemeData => {
    return (theme as SubThemeData).themeList !== undefined;
  };

  const renderThemeOptions = themeList.map((theme, index) => {
    if (hasSubThemeData(theme)) {
      const themeItem = theme as SubThemeData; // Explicitly treat theme as SubThemeData
      return (
        <optgroup label={themeItem.label} key={index}>
          {themeItem.themeList.map((themeItem) => {
            if (themeItem.isImplemented) {
              return (
                <option value={themeItem.value} key={themeItem.name}>
                  {displayModes[displayMode](themeItem)}
                </option>
              );
            } else {
              return null; // Skip rendering the option if subtheme is not implemented
            }
          })}
        </optgroup>
      );
    } else {
      const themeItem = theme as ThemeItem; // Explicitly treat theme as ThemeItem
      if (themeItem.isImplemented) {
        return (
          <option value={themeItem.value} key={themeItem.name}>
            {displayModes[displayMode](theme)}
          </option>
        );
      } else {
        return null; // Skip rendering the option if theme is not implemented
      }
    }
  });
  

  return (
    <div className="theme-selector">
      <div className='icon-wrapper'>

      <FaPalette size={24} className="icon-color" style={{ marginRight: '0.5em' }} />
      </div>
      <select value={JSON.stringify(selectedTheme)} onChange={handleThemeChange}>
        {renderThemeOptions}
      </select>
    </div>
  );
};

export default ThemeSwitcher;
