import { createContext } from 'react';

export interface BaseTheme {
  name: string;
  file: string;
  description: string;
  metadata: {
    author: string;
    version: string;
    releaseDate: string;
  };
  iconImage: string;
  previewImage: string;
  isImplemented: boolean;
}

export interface Theme extends Record<string, BaseTheme> {}

export interface SubThemes extends Record<string, Record<string, BaseTheme>> {}

export interface Page {
  backgroundImages: Record<string, string>;
  backgroundVideos: Record<string, string>;
}

export interface Pages extends Record<string, Page> {}

export enum TooltipMode {
  Hover = "hover",
  Direct = "direct",
}

export enum DisplayMode {
  Icon = "icon",
  Text = "text",
  Both = "both",
}

export interface ThemeContextProps {
  currentTheme: string;
  currentSubTheme: string;
  themes: Theme;
  subThemes: SubThemes;
  pages: Pages;
  displayMode: DisplayMode;
  tooltipMode: TooltipMode;
  changeTheme: (theme: string, subTheme?: string) => void;
}

export interface ThemeConfig {
  displayMode: DisplayMode;
  tooltipMode: TooltipMode;
  themes: Theme;
  subThemes: SubThemes;
  pages: Pages;
}

export const ThemeContext = createContext<ThemeContextProps>({
  currentTheme: 'dark',
  currentSubTheme: '',
  themes: {},
  subThemes: {},
  pages: {},
  displayMode: DisplayMode.Both,
  tooltipMode: TooltipMode.Hover,
  changeTheme: () => {},
});
