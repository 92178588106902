import { ChangeEvent, useContext, useState, useEffect } from 'react';
import { ThemeContext, ThemeContextProps } from '../ThemeContext';

interface ThemeSelection {
  theme: string;
  subTheme: string;
  name: string;
}
export interface SubThemeData { 
  label: string, 
  themeList: ThemeItem[] }

export interface ThemeItem {
  value: string;
  iconImage: string;
  description: string;
  name: string;
  isImplemented: boolean;
}

// Custom hook for theme selection logic
export const useThemeSelector = () => {
  const { 
    currentTheme,
    currentSubTheme, 
    changeTheme, 
    themes, 
    subThemes,
    displayMode,
    tooltipMode,
  }: ThemeContextProps = useContext(ThemeContext);

  const getInitialSelectedTheme = (): ThemeSelection => {
    const selectedThemeData = currentSubTheme 
      ? subThemes[currentTheme]?.[currentSubTheme] 
      : themes[currentTheme];

    const themeName = selectedThemeData?.name || '';
    return { 
      theme: currentTheme, 
      subTheme: currentSubTheme, 
      name: themeName,
    };
  };
  
  const [selectedTheme, setSelectedTheme] = useState<ThemeSelection>(getInitialSelectedTheme());

  useEffect(() => {
    const selectedThemeData = currentSubTheme 
      ? subThemes[currentTheme]?.[currentSubTheme] 
      : themes[currentTheme];

    const selectedThemeName = selectedThemeData?.name || '';
    
    setSelectedTheme({ 
      theme: currentTheme, 
      subTheme: currentSubTheme, 
      name: selectedThemeName,
    });
  }, [currentTheme, currentSubTheme, themes, subThemes])

  const handleThemeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = JSON.parse(event.target.value) as ThemeSelection;
    setSelectedTheme(selectedOption);
    changeTheme(selectedOption.theme, selectedOption.subTheme);
  };

  const renderSubThemeOptions = (themeKey: string): ThemeItem[] => {
    const subThemeOptions = subThemes[themeKey];

    return Object.keys(subThemeOptions).map((subThemeKey) => {
      const subTheme = subThemeOptions[subThemeKey];
      
      return {
        value: JSON.stringify({ 
          theme: themeKey, 
          subTheme: subThemeKey, 
          name: subTheme.name
        }), 
        iconImage: subTheme.iconImage,
        description: subTheme.description,
        name: subTheme.name ,
        isImplemented: subTheme.isImplemented
      };
    });
  };

  const themeList: (ThemeItem | SubThemeData)[] = Object.keys(themes).map((themeKey) => {
    const theme = themes[themeKey];
    const subThemeList = subThemes[themeKey];

    if (subThemeList) {
      return {
        label: theme.name, 
        themeList: renderSubThemeOptions(themeKey) 
      };
    } else {
      return {
        value: JSON.stringify({ 
          theme: themeKey, 
          subTheme: '', 
          name: theme.name,
        }), 
        iconImage: theme.iconImage,
        description: theme.description,
        name: theme.name,
        isImplemented: theme.isImplemented 
      };
    }
  });

  return { selectedTheme, handleThemeChange, themeList, displayMode, tooltipMode };
};
