import { useState, useEffect, useContext } from 'react';
import { ThemeContext, ThemeContextProps, DisplayMode } from './ThemeContext';

export interface ThemeSelection {
  theme: string;
  subTheme?: string;
  name: string;
}

export interface ThemeItem {
  value: string;
  name: string;
  iconImage: string;
  description: string;
  isImplemented: boolean;
  subThemeItems?: SubThemeItem[];
}

export interface SubThemeItem extends ThemeItem {
  parentName: string;
}

export const useGeneralThemeSelectorHook = (): {
  selectedTheme: ThemeSelection;
  changeThemeSelection: (theme: string, subTheme?: string) => void;
  themeList: ThemeItem[];
  displayMode: DisplayMode;
  tooltipMode: string;
} => {
  const {
    currentTheme,
    currentSubTheme,
    changeTheme,
    themes,
    subThemes,
    displayMode,
    tooltipMode,
  }: ThemeContextProps = useContext(ThemeContext);

  const getInitialSelectedTheme = (): ThemeSelection => {
    const selectedThemeData = currentSubTheme 
      ? subThemes[currentTheme]?.[currentSubTheme]
      : themes[currentTheme];

    const themeName = selectedThemeData?.name || '';
    return {
      theme: currentTheme,
      subTheme: currentSubTheme,
      name: themeName,
    };
  };

  const [selectedTheme, setSelectedTheme] = useState<ThemeSelection>(getInitialSelectedTheme());

  useEffect(() => {
    const selectedThemeData = currentSubTheme 
      ? subThemes[currentTheme]?.[currentSubTheme] 
      : themes[currentTheme];

    const themeName = selectedThemeData?.name || '';
    setSelectedTheme({ 
      theme: currentTheme, 
      subTheme: currentSubTheme, 
      name: themeName,
    });
  }, [currentTheme, currentSubTheme, themes, subThemes]);

  const changeThemeSelection = (theme: string, subTheme?: string) => {
    console.log("theme", theme, "subTheme",subTheme)
    setSelectedTheme({ theme, subTheme, name: themes[theme]?.name || '' });
    changeTheme(theme, subTheme);
  };

  const themeList: ThemeItem[] = Object.keys(themes).map((themeKey) => {
    const theme = themes[themeKey];
    const subThemeList = subThemes[themeKey];

    const themeItem: ThemeItem = {
      value: themeKey,
      name: theme.name,
      iconImage: theme.iconImage,
      description: theme.description,
      isImplemented: theme.isImplemented,
      subThemeItems: subThemeList ? Object.keys(subThemeList).map((subThemeKey) => {
        const subTheme = subThemeList[subThemeKey];

        return {
          value: subThemeKey,
          parentName: themeKey,
          name: subTheme.name,
          iconImage: subTheme.iconImage,
          description: subTheme.description,
          isImplemented: subTheme.isImplemented,
        };
      }) : undefined,
    };

    return themeItem;
  });

  return { selectedTheme, changeThemeSelection, themeList, displayMode, tooltipMode };
};
