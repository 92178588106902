import React from 'react';

// Home.jsx

// Home.jsx

const ModelsZone: React.FC = () => {
  return (
    <main className='home-content'>
      <div className="block-3d">3D Block</div>

      <div className="circle-image">
        <img src="Mafia2.png" alt="Description" />
      </div>
      <h1 className='home-title'>Welcome to Dija Studios!</h1>
      <h1>The site is still under construction</h1>
      <div className='home-text'>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Curabitur lacinia mattis mi nec placerat.</p>
        <p>Mauris ut neque ut tortor pellentesque pulvinar.</p>
      </div>
    </main>
  );
};

export default ModelsZone;
