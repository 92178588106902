import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './themes/ThemeProvider';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Studio from './pages/Studio';
import Academy from './pages/Academy';
import ModelsZone from './pages/ModelsZone';
import MakeupZone from './pages/MakeupZone'

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <Router>
        <div className="app">
          <Header />
          <main className='main-react-component '>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/modelszone" element={<ModelsZone />} />
              <Route path="/makeupzone" element={<MakeupZone />} />
              <Route path="/studio" element={<Studio />} />
              <Route path="/academy" element={<Academy />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;
