import React, { useEffect, useState } from 'react';

interface ModalProps {
  isOpen: boolean;
  content: string;
  handleMouseLeave: () => void;
  selectRef: React.RefObject<HTMLDivElement>;
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, content, handleMouseLeave, selectRef }) => {
  const [modalPosition, setModalPosition] = useState<'left' | 'right'>('right');

  useEffect(() => {
    const handleResize = () => {
      const selectRect = selectRef.current?.getBoundingClientRect();
      if (selectRect) {
        const windowWidth = window.innerWidth;
        const spaceLeft = selectRect.left;
        const spaceRight = windowWidth - selectRect.right;

        if (spaceLeft < 350 && spaceLeft < spaceRight) {
          setModalPosition('left');
        } else {
          setModalPosition('right');
        }
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectRef]);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={`custom-modal ${modalPosition}`}>
      <div className="modal-content" onMouseLeave={handleMouseLeave}>
        {content}
      </div>
    </div>
  );
};

export default CustomModal;
