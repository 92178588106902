import React from 'react';

// Home.jsx

// Home.jsx

const Academy: React.FC = () => {
  return (
    <main className='home-content'>
      <h1 className='home-title'>Welcome to Your School!</h1>
      <div className='home-text'>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Curabitur lacinia mattis mi nec placerat.</p>
        <p>Mauris ut neque ut tortor pellentesque pulvinar.</p>
      </div>
    </main>
  );
};

export default Academy;
