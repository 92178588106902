// React Component
import React from 'react';
import { text } from 'stream/consumers';

const Hero: React.FC = () => {
  return (
    <div className="hero">
      <div className="hero-left">
        <h1 className="hero-title">Dija Studios</h1>
        <h2 className="hero-title">Is a digital hub and an agency to facilitate projects involving photography, filming,
         models, makeup artist and training related to these activities </h2>
      </div>
      <div className="hero-right">
          <div className="circle-image">
          <img src="Mafia2.png" alt="Description" />
        </div>
        <p className="my-signature" >I would like to welcome you to Dija studios, the site and the digital hub 
        project is still under construction, in the mean time if you have any ideas for the hub or 
        interest in a collaboration project, 
        please feel free to contact me at <a href="mailto:mo.ibrahimsen@DijaStudios.com" className="highlighted-email">mo.ibrahimsen@DijaStudios.com</a>
</p>
        <p className="my-signature">Dr. Eng. M.Ibrahimsen</p>
      </div>
    </div>
  );
};

export default Hero;
