import React, { useState, useEffect } from 'react';
import { 
  ThemeContext, 
  Theme, 
  SubThemes, 
  Pages,
  DisplayMode,
  TooltipMode,
  ThemeConfig,
} from './ThemeContext';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ children }) => {
  function getInitialTheme() {
    const storedTheme = localStorage.getItem('theme');
    const storedSubTheme = localStorage.getItem('subTheme');
    const defaultTheme = storedTheme || 'bazrameet';
    const defaultSubTheme = storedSubTheme || '';
    return { defaultTheme, defaultSubTheme };
  }

  const [currentTheme, setCurrentTheme] = useState<string>(() => {
    const { defaultTheme } = getInitialTheme();
    return defaultTheme;
  });
  
  const [currentSubTheme, setCurrentSubTheme] = useState<string>(() => {
    const { defaultSubTheme } = getInitialTheme();
    return defaultSubTheme;
  });
  
  const [themes, setThemes] = useState<Theme>({});
  const [subThemes, setSubThemes] = useState<SubThemes>({});
  const [pages, setPages] = useState<Pages>({});
  const [tooltipMode, setTooltipMode] = useState<TooltipMode>(TooltipMode.Hover); // Add tooltipMode state
  const [displayMode, setDisplayMode] = useState<DisplayMode>(DisplayMode.Both); // Add displayMode state
  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await fetch('/config/themes.json');
        const data: ThemeConfig = await response.json();
        
        setThemes(data.themes);
        setSubThemes(data.subThemes);
        setPages(data.pages);
        setDisplayMode(data.displayMode);
        setTooltipMode(data.tooltipMode); // Set tooltipMode from the JSON configuration      
      } catch (error) {
        console.error('Error fetching themes:', error);
      }
    };

    fetchThemes();
  }, []);

  useEffect(() => {
    const loadTheme = () => {
      const themeFile = themes[currentTheme]?.file;
      const subThemeFile = subThemes[currentTheme]?.[currentSubTheme]?.file;

      // Remove previous theme and subtheme stylesheets
      const existingThemeLinks = document.querySelectorAll('link[data-theme]');
      existingThemeLinks.forEach(link => {
        const themeAttr = link.getAttribute('data-theme');
        if (themeAttr !== 'common' && themeAttr !== 'default') {
          document.head.removeChild(link);
        }
      });

      const existingSubThemeLinks = document.querySelectorAll('link[data-subtheme]');
      existingSubThemeLinks.forEach(link => {
        const subThemeAttr = link.getAttribute('data-subtheme');
        if (subThemeAttr !== 'common' && subThemeAttr !== 'default') {
          document.head.removeChild(link);
        }
      });

      if (themeFile) {
        const themeLink = document.createElement('link');
        themeLink.rel = 'stylesheet';
        themeLink.href = `/assets/css/${themeFile}`;
        themeLink.setAttribute('data-theme', currentTheme);
        document.head.appendChild(themeLink);
      }

      if (subThemeFile && currentSubTheme) {
        const subThemeLink = document.createElement('link');
        subThemeLink.rel = 'stylesheet';
        subThemeLink.href = `/assets/css/${subThemeFile}`;
        subThemeLink.setAttribute('data-subtheme', currentSubTheme);
        document.head.appendChild(subThemeLink);
      }
    };

    loadTheme();
  }, [currentTheme, themes, subThemes, currentSubTheme]);

  const changeTheme = (theme: string, subTheme?: string) => {
    localStorage.setItem('theme', theme);
    localStorage.setItem('subTheme', subTheme || '');
    setCurrentTheme(theme);
    setCurrentSubTheme(subTheme || '');
  };

  return (
    <ThemeContext.Provider
      value={{
        currentTheme,
        currentSubTheme,
        themes,
        subThemes,
        pages,
        displayMode,
        tooltipMode, // Include tooltipMode in the context value
        changeTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
