import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ThemeSwitcher from '../themes/themeSwitcher/ThemeSwitcher';
import ReactSelectSwitcher from '../themes/ReactSwitcher/ReactSelectSwitcher';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';


const Header: React.FC = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const activeStyle = {
    border: '2px solid currentColor',
    borderRadius: '8px',
    padding: '0.2rem 0.5rem'
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const navItems = [
    { path: "/", name: "Home" },
    { path: "/modelszone", name: "Models Zone" },
    { path: "/makeupzone", name: "Makeup Artists Zone" },
    { path: "/studio", name: "Studio and Filmography" }, // Consider updating duplicate paths
    { path: "/academy", name: "Academy and Training" } // Consider updating duplicate paths
  ];

  const handleNavLinkClick = () => {
    setMenuOpen(false);
  };

  const listNavLinks = (className: string) => {
    return (
      <div className={className}>
        {navItems.map((item) => (
          <NavLink
            key={item.name}
            to={item.path}
            className='navigation-link'
            style={location.pathname === item.path ? activeStyle : {}}
            onClick={handleNavLinkClick}
          >
            {item.name}
          </NavLink>
        ))}
      </div>
    );
  };

  return (
    <header className='header'>
      <div className='left-section'>
        <ReactSelectSwitcher />
        {listNavLinks("navigation")}
          <div className='menu-dropdown'>
          <IconButton
          className='custom-icon-button'
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleMenu}
        >
          <MenuIcon className='custom-menu-icon' />
            </IconButton>                       
            {menuOpen && (
              listNavLinks('dropdown-content')
            )}

        </div>
      </div>
      <div className='right-section'>
        <ThemeSwitcher />
      </div>
    </header>
  );
};

export default Header;
