import { useState } from 'react';
import { ThemeItem, SubThemeItem, useGeneralThemeSelectorHook } from '../useGeneralThemeSelectorHook';
import { DisplayMode } from '../ThemeContext';

export interface OptionData {
  value: string;
  label: string;
  data: ThemeItem | SubThemeItem;
}

export type SingleOptionType = OptionData;

interface GroupOptionType {
  label: string;
  options: OptionData[];
}

type OptionTypeBase = SingleOptionType | GroupOptionType;

interface UseReactSelectSwitcher {
  selectedOption: OptionData | null;
  modalContent: string;
  options: OptionTypeBase[];
  displayMode: DisplayMode;
  handleOptionMouseEnter: (option: SingleOptionType) => void;
  handleOptionMouseLeave: () => void;
  handleChange: (selectedOption: SingleOptionType | null) => void;
  isModalOpen: boolean;
}

export const useReactSelectSwitcher = (): UseReactSelectSwitcher => {
  const { selectedTheme, changeThemeSelection, themeList, displayMode } = useGeneralThemeSelectorHook();

  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOptionMouseEnter = (option: SingleOptionType) => {
    setModalContent(option.data.description || '');
    setIsModalOpen(true);
  };

  const handleOptionMouseLeave = () => {
    setIsModalOpen(false);
    setModalContent('');
  };

  const handleChange = (selectedOption: SingleOptionType | null) => {
    if (!selectedOption) return;
    const { data } = selectedOption;
    if ('parentName' in data) {
      changeThemeSelection(data.parentName, data.value);
    } else {
      changeThemeSelection(data.value);
    }
    handleOptionMouseLeave();
  };

  const options: OptionTypeBase[] = themeList.map((theme): OptionTypeBase => {
    if (theme.subThemeItems && theme.subThemeItems.length > 0) {
      return {
        label: theme.name,
        options: theme.subThemeItems.map((subTheme) => ({
          value: subTheme.value,
          label: subTheme.name,
          data: subTheme,
        })),
      };
    } else {
      return {
        value: theme.value,
        label: theme.name,
        data: theme,
      };
    }
  });

  const selectedOption = options
  .flatMap((option) => ('options' in option ? option.options : [option]))
  .find((option) => option.value === (selectedTheme.subTheme || selectedTheme.theme)) || null;


  return {
    selectedOption,
    modalContent,
    options,
    displayMode,
    handleOptionMouseEnter,
    handleOptionMouseLeave,
    handleChange,
    isModalOpen,
  };
};
