
import React, { useState } from 'react';
import Collapsible from '../components/Collapsible';
import { SlideProps } from '../components/Slide';  // import the type from the Slide component
import data from "../Data/thermo2.json";

type Topic = {
  title: string;
  slides: SlideProps[];  // directly use SlideProps here
}

type Data = {
  topics: Topic[];
}
type ListItem = string | { text: string; nested: ListItem[]; };

type FullTextContent = ListItem[];

interface HalfVerticalContent {
  text: FullTextContent;
  image: string;
}

interface HalfHorizontalContent {
  text: FullTextContent;
  image: string;
}

type TopicSlide = {
  type: 'fullText' | 'halfVertical' | 'halfHorizontal';
  bulletType: 'itemize' | 'enumerate' | 'bullet';
  textSize: 'small' | 'normal' | 'large';
  content: FullTextContent | HalfVerticalContent | HalfHorizontalContent;
};



const Studio: React.FC = () => {
  const [activeId, setActiveId] = useState<number | null>(null);

  const mainStyle: React.CSSProperties = {
    justifyContent: 'flex-start',
  };

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingTop: '20px',
    marginTop: '20px',
  };

  return (
    <main className='home-content'>
      <div className="block-3d">3D Block</div>

      <div className="circle-image">
        <img src="Mafia2.png" alt="Description" />
      </div>
      <h1 className='home-title'>Welcome to Dija Studios!</h1>
      <h1>The site is still under construction</h1>
      <div className='home-text'>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <p>Curabitur lacinia mattis mi nec placerat.</p>
        <p>Mauris ut neque ut tortor pellentesque pulvinar.</p>
      </div>
    </main>
  );
};


export default Studio;